@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600&display=swap')
@import "styles/variables"

@import "styles/Header"
@import "styles/Detections"
@import "styles/FormAddGate/NewGate"
@import "styles/FormAddGate/FormAndCameras"
@import "styles/FormAddGate/Camera"
@import "styles/FormAddGate/ImageSettig"
//@import "styles/FormAddGate/BigImg"
@import "styles/Gate"
@import "styles/SignIn"
@import "styles/Configuration"
@import "styles/Errors"
@import "styles/Fids"
@import "styles/Canvas"
@import "styles/Turnaround"
@import "styles/Live"
@import "styles/SkeletonLoadings"
@import "styles/Users"
@import "styles/Image"
@import "styles/LiveView"
@import "styles/Mock"
@import "styles/System"
@import "styles/Performance"
@import "styles/Spinner"
@import "styles/AuditLogs"

@import "styles/FormAddGate/BigImgNewStyles"

@import "styles/FormAddGate/BigImgNewStyles"

*
  margin: 0
  padding: 0
  box-sizing: border-box
  font-family: 'Titillium Web', sans-serif
  text-decoration: none


html, body
  height: 100%
  margin: 0
  overflow-x: hidden

.markItem
  background-color: $yellow
  color: #000000
  padding: 2px
  border-radius: 5px
  margin-right: 0
  font-size: 0.7rem


.wrapperAllGates
  display: flex
  justify-content: center
  align-items: center
  flex-direction: column
  margin-top: 30px

.wrapperAllGates > div
  margin-bottom: 20px

.App-header
  text-align: center


/*STYLES FOR NEW TABLE*/

.ant-table-thead
  .ant-table-cell
    background-color: $easy-blue !important
    color: $white !important
    text-align: center !important
    font-weight: 550 !important
    text-transform: capitalize
    &:before
      background-color: white !important
      height: 1.4rem !important

.wrapper_config
  .ant-table-tbody > tr:nth-child(2n)
    background-color: $middleWhite !important
    color: $black

.ant-table-filter-trigger,
.ant-table-column-sorter,
.ant-table-column-sorter-full
  .anticon
    &:hover
      color: $white
      transition: all 0.4s ease-in-out

  .anticon, .ant-table-column-sorter-inner
    color: black
    .ant-table-column-sorter-down.active, .ant-table-column-sorter-up.active
      color: $lightOrange !important
.ant-table-filter-trigger.active
  .anticon, .anticon-search
    color: $lightOrange !important


.ant-table-tbody > tr
  &:hover
    .ant-table-cell
      &:first-child, &:last-child
        border-radius: 0 !important

.blockFrames
  display: flex
  justify-content: space-evenly
  align-items: center


.blockFramesLink
  margin: 0
  text-align: center

.blockFrames > .ant-tag
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center

.blockFrames > .ant-tag:hover > a
 color: $white

.containerNewTable
  width: 100%
  max-width: calc(100% - 40px)
  margin: 15px auto

.ant-table-row
  height: 60px !important
  text-align: center

.ant-table-column-sort
  padding: 10px !important
  width: 180px !important
  background-color: rgb(211, 211, 211) !important

.sectionNewTable
 .ant-table-cell:nth-child(3),
 .ant-table-cell:nth-child(4),
 .ant-table-cell:nth-child(5),
 .ant-table-cell:nth-child(6),
 .ant-table-cell:nth-child(7)
    width: 210px !important

.sectionNewTable
  padding-bottom: 20px
  .ant-table-cell:nth-child(8)
    width: 210px !important

.ant-table-cell
  padding: 8.5px !important
  font-size: 13px
  font-weight: 600

.emptyCameraRow
  height: 60px
  line-height: 60px


.wrapper_open_close
  width: 100%
  background-color: #3C60B5

.wrapper_open_close.openTest
  height: 500px
  transition: 0.4s linear

.wrapper_open_close.closeTest
  height: 0



// expandable table styles
.performanceWrapper, .wrapperMainPage
  .nestedTable
    .ant-table-thead
      .ant-table-cell
        background-color: #f0f0f0 !important
        border-start-start-radius: 0 !important
        border-start-end-radius: 0 !important
        color: black !important
        padding: 10px !important

        &:before
          background-color: rgba(5, 5, 5, 0.06) !important

    tbody
      .disabled-row
        background-color: #EBEBE4 !important
        pointer-events: none !important
        color: #C6C6C6 !important
      .ant-table-row-hover
        &:hover
          background-color: #CECECE !important
          opacity: 1

  .ant-table-expanded-row-level-1
    .ant-table-cell
      border-inline-end: 1px solid rgba(5, 5, 5, 0.06) !important
      padding: 15px !important





.edit_input_block
  display: flex
  justify-content: center
  align-items: center



.test_resize_container
  .react-transform-wrapper, .transform-component-module_wrapper__7HFJe,
  .react-transform-component, .transform-component-module_content__uCDPE
    border-radius: inherit
    width: 100%
    justify-content: center

    img, canvas
      width: 100%
      height: 100%

    canvas
      position: absolute
      top: 0
      left: 0
      right: 0
      bottom: 0