@import "GlobalStyles"

.wrapper_config
  width: 90%
  margin: 10px auto 0 auto

  &__camera-modal-info-wrapper
    @include defModalClasses
    background-color: rgba(31, 97, 141, 0.8)

  &__camera-modal-info-wrapper.open
    @include defOpenModalClasses

  &__camera-modal-info-wrapper.close
    @include defCloseModalClasses

  &__config_table_container
    .ant-table-tbody td:nth-child(2)
      display: flex !important
      justify-content: center
      align-items: center
    .ant-table-tbody > tr > td
      border: none

    .ant-table-tbody > tr.ant-table-row
      &:hover > td
        background-color: transparent
    .ant-table-thead .ant-table-cell
      text-align: center

    .editable_disabled_btn_container,
    .editable_btn_container,
    .setting_btn_container
      display: flex
      justify-content: space-around
      align-items: center
    .editable_disabled_btn_container,
    .configuration-camera,
    .editable_btn_container,
    .setting_btn_container
      .ant-btn
        @include defBntIcon
        &:hover
          background: transparent
    .editable_disabled_btn_container
      .ant-btn
        &:first-child
          color: rgba(0, 0, 0, 0.25)
          cursor: not-allowed

    .configuration-camera
      width: 120px
      margin-right: 25px

      &:last-child
        margin: 0
      &__camera-title
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        .camera-icon-result-container
          display: flex
          align-items: center
          &__camera-icon
            @include defBntIcon
            margin: 0
            .ant-btn-icon
              width: 20px
              height: 20px
              svg
                width: inherit
                height: inherit
          &__camera-icon.connected
            .ant-btn-icon
              svg
                fill: $green
          &__camera-icon.disconnected
            .ant-btn-icon
              svg
                fill: $red

          &__camera-icon.disabled
            .ant-btn-icon
              svg
                fill: $grey
          &__calibration-result
            width: 20px
            height: 20px
            font-size: 1.2rem

          &__calibration-result > span
            display: flex
          &__calibration-result.animated
            svg
              animation: spin 2s infinite linear
              transform-origin: center
              fill: $blue !important
          &__calibration-result.excellent
            svg
              fill: $green !important
          &__calibration-result.good
            svg
              fill: #e3f542 !important
          &__calibration-result.bad, &__calibration-result.failed
            svg
              fill: $red !important
          &__calibration-result.failed
            display: flex
            justify-content: center
            align-items: center
            position: relative
            svg
              display: block

            .failed-icon-result
              display: flex
              justify-content: center
              align-items: center
              width: 100%
              height: 100%
              position: absolute
              top: 0
              left: 0
              opacity: 0.7
              svg
                display: block
                fill: $black !important

          &__calibration-result.bridge
            display: none


        .camera-number
          font-size: 1rem
          font-weight: 600

      &__btn-camera-container
        margin-top: 5px
        display: flex
        justify-content: space-around
        align-items: center

        .btn-camera
          @extend .iconButton
        .btn-toggle.modified
          background: $easy-blue !important
          &:hover
            background: $easy-blue !important
        .btn-toggle.unaltered
          background: $red
          &:hover
            background-color: $red !important


  .config-table-title
    display: flex
    justify-content: center
    align-items: center

    button
      @extend .defaultButton
      width: 150px !important
      height: 32px !important
      color: #fff !important
      font-weight: 550
      line-height: 1.2rem
      text-transform: uppercase

  .configuration-camera-info-container
    position: absolute
    top: 0
    left: 50%
    transform: translateX(-50%)
    z-index: 100
    width: 95%
    height: 100%
    padding: 5px 0 10px 0

    &__camera-title
      position: relative
      height: 35px
      display: flex
      justify-content: center
      align-items: center
      background-color: $white
      border-radius: 5px

      p
        font-weight: 700
        span
          font-weight: 400
      .ant-btn
        position: absolute
        right: 5px
        top: 50%
        transform: translateY(-50%)
        background-color: transparent
        padding: 3px
        width: auto
        height: auto
        color: $red
        border-color: $red

    &__zoomable-carousel-frame-container
      width: 100%
      height: calc(100% - 85px)
      position: relative
      border-radius: 10px
      padding: 5px 0
      display: flex
      justify-content: center
      align-items: center

      .ant-carousel
        width: 100%
        .slick-next, .slick-prev
          width: auto
          height: auto
          opacity: 0.6 !important
          color: $black
          font-weight: 600
          &:hover
            opacity: 1 !important
            color: $white

        .slick-next, .slick-prev
          font-size: 1.1rem
          &:after
            top: -5.5px !important
            inset-inline-start: auto
        .slick-next
          &:after
            right: -12px !important
        .slick-prev
          &:after
            left: -12px !important
        .slick-disabled
          display: none !important

      .ant-carousel,
      .slick-slider,
      .slick-list,
      .slick-track
        height: 100% !important

        .slick-slide > div:first-child
          height: 100% !important
          display: flex
          justify-content: center

        .react-transform-wrapper, .transform-component-module_wrapper__7HFJe,
        .react-transform-component, .transform-component-module_content__uCDPE
          border-radius: inherit
          height: 100%
          justify-content: center

          img
            width: 100%
            height: 100%
            object-fit: contain

    &__camera-description
      height: 50px
      display: flex
      justify-content: center
      align-items: center
      background-color: $white
      border-radius: 5px

      p
        text-transform: capitalize
        font-weight: 700
        margin-right: 10px
        span
          font-weight: 400

.ant-tooltip
  .ant-tooltip-content
    .ant-tooltip-inner
      .calibration_tooltip_result_container
        display: flex
        flex-direction: column
        align-items: center
        justify-content: center
        text-align: center
        font-size: 0.8rem
        font-weight: 500