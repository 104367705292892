@import "variables"

#detectors
  width: 20%
.pointer-events-none
  pointer-events: none


.wrapper
  cursor: not-allowed


.liveComponent
  &__liveHeader
    text-align: center
    margin-bottom: 10px
    font-weight: 600
    font-size: 1.1rem

  &__liveContainer
    display: flex
    width: 1920px
    max-width: 100%
    padding: 0 10px
    justify-content: center
    text-align: center

    .liveImageContainer
      width: 950px
      height: 100%

      .liveImageDetections
        margin-top: 5px

        &__liveTextTime
          display: flex
          flex-direction: column
          span:first-child
            font-weight: 600
            font-size: 0.9rem

          span:last-child
            font-size: 1.1rem
            text-decoration: underline

    .liveTurnInformation
      width: 500px
      text-align: center
      .wrapperTurnInfo
        h2
          font-weight: 600
          margin: 0
          text-decoration: underline

      .turnInformation
        display: flex
        flex-direction: column

        span
          font-size: 0.9rem
