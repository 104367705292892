@import "GlobalStyles"

.containerNewTable
  .ant-table-cell:nth-child(1),
  .ant-table-cell:nth-child(2)
    width: 120px !important
  .ant-table-cell:nth-child(3),
  .ant-table-cell:nth-child(4),
  .ant-table-cell:nth-child(5),
  .ant-table-cell:nth-child(6),
  .ant-table-cell:nth-child(7),
  .ant-table-cell:nth-child(8)
    width: 150px !important
    font-size: 0.75rem
  .ant-table-cell:nth-child(9)
    width: 180px
  .ant-table-cell:nth-child(10)
    width: 165px !important
  .ant-table-tbody
    .ant-table-cell
      padding: 6.5px !important

  .ant-table-thead
    .ant-table-cell
      text-align: center
    .ant-table-column-has-sorters
      .ant-table-column-sorter-full
        margin-left: 0
        .ant-table-column-sorter-inner
          .ant-table-column-sorter-up.active,
          .ant-table-column-sorter-down.active
            margin-right: 0
  .markTag
    margin-right: 0
    padding: 2px 1px
    color: $black
    font-weight: 500
    font-size: 0.7rem
  .markTag.status
    padding: 2px 5px

  .ant-pagination
    margin-top: 10px

  .ant-table-thead
    .ant-table-cell
      text-align: center

.ant-table-row
  cursor: default!important

.idFidCamera
  margin: 0
  font-weight: 200
  font-size: 0.75rem

.wrapperFidsIdButton
  position: relative
  &__fidsIdButton
    background-color: transparent
    border: none
    color: $darkBlue
    cursor: pointer
    text-decoration: underline

    &:hover
      transform: scale(1.1)
      transition: all 0.4s linear

.blockEvents
  flex-direction: column
  //width: 150px
  margin: 0 auto

  &__blockHorizon
    display: flex
    flex-direction: row
    margin-top: 5px

  &__wrapperEventCamera
    &:first-child
      margin-right: 10px

  &__totalBlock
    position: relative
    width: inherit

    button
      background-color: transparent
      border: none
      font-size: 1.05rem
      color: #FF8500
      cursor: pointer

      &:hover
        color: #3C60B5
        transition: all 0.4s linear

    .totalText
      display: flex
      justify-content: center
      width: inherit
      .ant-tag
        margin: 0 0 0 10px
        display: flex
        justify-content: center
        width: 20px
        height: 20px
        text-align: center
  
    .totalButton
      position: absolute
      left: 0
      top: 50%
      transform: translateY(-50%)
      color: #FF8500



.blockFrames
  justify-content: center
  &__wrapperFidsCamera
    display: flex
    flex-direction: column
    padding: 0 5px
    .fidsCamera
      @extend .iconButton
      width: 100%
      span
        color: $lightOrange

      &:disabled
        cursor: not-allowed
        &:hover
          transform: scale(1)
        span
          color: $grey

.blockEvents
  &__wrapperEventCamera
    padding: 0

.wrapperFidsTurnInfo
  @extend .wrapperModal
  padding: 0
  //margin-top: 70px

.wrapperFidsTurnInfo.active
  @extend .showModal

.wrapperFidsTurnInfo.notActive
  @extend .hideModal

.contentFidsTurnInfo.active
  @extend .showCard
  margin: 0

.contentFidsTurnInfo.notActive
  @extend .hideCard

.contentFidsTurnInfo.active
  width: 400px
  padding: 10px
  border-radius: 10px
  background-color: #ffffff
  text-align: center
  .turnInformation
    display: flex
    flex-direction: column
  button
    width: 150px
    margin-top: 10px
    color: $white
    text-transform: uppercase
    background-color: #FF8500
    &:hover
      border: 1px solid $black
      transform: scale(1.05)
      transition: all 0.4s ease
      color: inherit


