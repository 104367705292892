@import "GlobalStyles"

.wrapperMainPage
  width: 80%
  margin: 10px auto 0 auto
  tbody
    .ant-table-row-level-0
      &:hover
        .ant-table-cell
          background-color: #d9d9d9 !important

  &__liveViewColumn
      display: flex
      flex-direction: column
      justify-content: center
      align-items: center

      .ant-tag, .ant-badge
        margin-right: 0
        &:first-child
          margin-bottom: 10px
      .ant-tag
        color: $black
      .ant-badge
        .ant-badge-status-dot
          width: 13px
          height: 13px
      .turnTimeLineMark
        span
          &:first-child
            margin-right: 5px
          &:last-child
            margin-left: 5px

  .turnaroundColumn
    .ant-tag
      cursor: pointer
      &:hover
        transform: scale(1.1)
        transition: all 0.4s linear

  .streamCameraItem
    width: 60px
    height: 60px
    margin: 0 auto
    border-radius: 10px
    border: 1px solid #000000
    &:hover
      transform: scale(1.1)
      transition: all 0.4s linear
    .ant-image
      border-radius: inherit
    a
      display: inline-block
      width: 100%
      height: 100%
      .ant-image-img
        object-fit: cover



.wrapperMainTurnInfo
  @extend .wrapperModal
  padding: 0
  //margin-top: 70px

.wrapperMainTurnInfo.active
  @extend .showModal

.wrapperMainTurnInfo.notActive
  @extend .hideModal

.contentMainTurnInfo.active
  @extend .showCard
  margin: 0

.contentMainTurnInfo.notActive
  @extend .hideCard

.contentMainTurnInfo.active
  width: 400px
  padding: 10px
  border-radius: 10px
  background-color: #ffffff
  text-align: center
  .turnInformation
    display: flex
    flex-direction: column

  button
    width: 150px
    margin-top: 10px
    color: #ffffff
    text-transform: uppercase
    background-color: #FF8500