@import "GlobalStyles"

.wrapperLinkImg
  button
    background-color: transparent
    border: none

    img
      width: 300px
      height: 180px

  .linkSecondTurnImg
    cursor: pointer

.react-transform-wrapper
  border-radius: inherit

.cctv-text
  color: $easy-blue
  font-weight: 600
  font-size: 1rem
  margin-bottom: 5px

.timestampButton, .bboxButton
  button
    border: 1px solid #000000
    color: cornflowerblue
    line-height: 0

    span
      font-size: 0.7rem
      font-weight: 600

.turnaroundSection
  position: relative
  width: 100%
  max-width: 100%
  padding: 5px 15px 0 15px

  .ant-drawer-open
    height: calc(100vh - 70px)

  .ant-drawer-content
    background-color: #1f618d

    .ant-drawer-header-close-only
      padding: 10px 5px
      border-bottom: 1px solid $black

      .ant-drawer-header-title
        button
          font-size: 1.4rem
          font-weight: 600
          color: $black

          &:hover
            transform: scale(1.1)
            transition: all 0.4s linear
            color: $white

  .turnInfoSection
    text-align: center

    .wrapperTurnInfo
      h2
        color: $black
        font-weight: 600

      .turnInformation
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        font-weight: 600
        color: #BAA2A2

  &__wrapperTurnInfo
    width: 100%
    height: 100%

  .blockFrameButtons
    width: 100%
    display: flex
    justify-content: space-between
    align-items: center
    margin: 10px auto 0 auto

    &__jumpBySecondBlock
      display: flex
      width: 100%
      justify-content: space-around

    .ant-btn-primary
      &:disabled
        opacity: 1
        border: none !important
        // fix important
        color: $white !important
        // fix important
        background-color: $smoke !important
    // fix important

    button
      @extend .defaultButton
      line-height: 1.1rem
      border-radius: 10px

  &__wrapperLeftHideBlock, &__wrapperRightHideBlock
    border: 5px solid $middleSmoke
    border-bottom: none

    .ant-drawer-header
      border-bottom: 2px solid $white !important
      padding: 5px 15px

      .ant-drawer-header-title
        .ant-drawer-close
          margin-right: 0
          font-size: 1.3rem
          color: $white !important

          &:hover
            transition: all 0.3s linear !important
            transform: scale(1.2) !important
            color: $black !important

        .ant-drawer-title
          text-align: center
          font-size: 1.5rem

        .ant-drawer-close, .ant-drawer-title
          font-weight: 600
          color: $white

  &__wrapperLeftHideBlock
    .ant-drawer-header
    .ant-drawer-header-title
      flex-direction: row-reverse

  &__wrapperRightHideBlock.bigSidebar
    .textRightContainer
      &__wrapperSecondCamera
        display: flex
        flex-wrap: wrap
        justify-content: space-around

  &__wrapperRightHideBlock
  .textRightContainer
    display: flex
    flex-direction: column
    align-items: center
    position: relative
    text-align: center
    height: 100%

    &__wrapperSecondCamera
      margin-bottom: 5px

      h5
        color: #ffffff
        font-weight: 600
        font-size: 1rem

  &__turnaroundInformation
    text-align: center

    .turnaroundInfoHeader
      display: flex
      justify-content: space-between
      align-items: center
      width: 100%
      margin: 0 auto 5px auto

      .timeline
        width: 60%

      .openSidebarButton
        @extend .defaultButton
        width: 150px !important
        // fix important
        color: #ffffff
        border: 2px solid #000000
        border-radius: 10px
        font-size: 1rem !important
        // fix important
        line-height: 1rem

        &:hover
          transform: scale(1.1)
          transition: all 0.4s linear

    .bodyTurnaroundInfo
      display: flex
      justify-content: space-between

      .ant-select-selection-placeholder
        color: #515151
        font-weight: 600

      .imgContainer
        width: 44%
        height: 100%

        .textWhichFrame
          margin: 0
          font-weight: 600
          line-height: 1.9rem

        .turnTimeDate
          text-transform: capitalize
          font-size: 1.05rem
          font-weight: 500

        .canvasFrameCheckBoxes
          display: flex
          justify-content: center
          margin-bottom: 5px

          .ant-checkbox-wrapper
            span
              &:last-child
                font-weight: 600
                font-size: 0.8rem

            .ant-checkbox
              .ant-checkbox-inner
                width: 15px
                height: 15px

            @media (max-width: 1480px)
              span
                &:last-child
                  font-size: 0.75rem
                  padding-inline-start: 4px
                  padding-inline-end: 4px
              .ant-checkbox
                .ant-checkbox-inner
                  width: 13px
                  height: 13px



        .wrapperSwitchButtons
          margin: 0
          display: flex
          justify-content: center
          align-items: center

          button
            cursor: pointer
            position: relative
            width: 100px
            background-color: $easy-blue
            border-radius: 5px
            color: $white
            font-size: 0.85rem
            height: 30px
            border: 2px solid $black
            font-weight: 600
            text-transform: uppercase
            line-height: 25px

            &:active
              top: 5px

            &:first-child
              margin-right: 10px

            &:nth-child(2)
              margin-right: 10px

          button:disabled:nth-child(1),
          button:disabled:nth-child(3)
            background-color: #797979
            cursor: not-allowed
            top: 0


      &__tablesWrapper
        width: 55%
        max-width: 100%
        position: relative

        .ant-collapse-item
          margin-bottom: 10px
          background: rgb(208, 208, 208)
          border-radius: 8px
          border: none

        .turnaroundTableCollapse
          .ant-collapse-content-box
            padding: 0

          &__actionsEventButtonsBlock
            display: flex
            justify-content: center
            align-items: center
            //
            button
              width: 32px
              height: 32px
              display: flex
              justify-content: center
              align-items: center
              padding: 0

              svg
                width: 1.05rem
                height: 1.05rem

              @media (max-width: 1640px)
                width: 28px
                height: 28px

              .actionEventButton.approvedButton
                background-color: #389e0d

              .actionEventButton.notApprovedButton
                background-color: #f5222d

            button, .ant-tooltip-disabled-compatible-wrapper
              margin-right: 5px

              &:last-child
                margin-right: 0
          //

          .ant-collapse-header
            padding: 3px 16px

          .ant-table-container, .ant-table-header
            border-top-left-radius: 0
            border-top-right-radius: 0

            .ant-table-cell
              padding: 5.5px 8.5px !important
              // fix important
              &:first-child
                border-top-left-radius: 0

              &:last-child
                border-top-right-radius: 0

          .ant-table-footer
            padding: 0
            background-color: #D0D0D0

            span
              font-weight: 600

            .footerMainEventTable,
            .footerPotentialEventTable
              display: flex
              align-items: center
              padding: 3px
              margin: 0 5px

              &__wrapperColorInstructions
                display: flex
                align-items: center

            .footerMainEventTable
              justify-content: space-between

            .footerPotentialEventTable
              justify-content: flex-start

            .colorInstructions
              display: flex
              align-items: center

              &:last-child
                margin-left: 10px

              &__color
                width: 15px
                height: 15px
                border: 1px solid $black
                border-radius: 3px

              &__text
                margin-left: 5px

          .ant-table-body
            .wrapper_row_manually_class
              background-color: #ffccc7

            .wrapper_row_not_manually_class
              background-color: #cde9ff

            .shadedColumn
              .indexedEventColumn
                background-color: #91caff

            @media (max-width: 1640px)
              .camera, .confidence, .process, .event, .position, .offset
                font-size: 0.7rem
              .timestamp .timestampButton button
                font-size: 0.6rem

            &:last-child
              .potential_red_row_event
                background-color: #ffccc7

              .potential_yellow_row_event
                background-color: #ffffb8

              .ant-table-row, .ant-table-row-level-0
                height: 30px !important
        // fix it

        .summaryEventTableButtonsBlock
          display: flex
          justify-content: space-around
          align-items: center

          &__finishValidationButton
            margin-left: 10px


        .turnaroundTableCollapse.panel
          .ant-table-container
            position: relative
            max-height: calc(100vh - #{$sum_busy_space_for_table})
            transition: 0.4s ease-in-out
            overflow: auto


        .turnaroundTableCollapse.panels
          .ant-table-container
            position: relative
            max-height: calc((100vh - #{$sum_busy_space_for_all_tables}) / 2)
            overflow: auto

        .ant-table-title
          padding: 3px
          font-weight: 600

        .ant-table-sticky-holder, .ant-table-body
          table
            table-layout: inherit !important
        // fix important

        .ant-table-summary
          text-align: center

          button
            padding: 0 15px
            font-size: 0.8rem
            height: 25px

        .ant-table-thead
          position: sticky
          top: 0
          z-index: 1

        .ant-table-cell
          font-size: 12px

        .ant-table-row
          height: 45px !important
// fix important

.turnSlideBlock
  width: 50%
  margin: 0 auto

  .ant-slider-rail,
  .ant-slider-track
    height: 8px !important
  // fix important
  .ant-slider-dot
    width: 11px
    height: 11px

  .ant-slider-handle
    width: 13px
    height: 13px

    &:after
      width: 13px
      height: 13px

    &:hover::after
      box-shadow: 0 0 0 3px #1677ff !important
  // fix important

  .ant-slider-mark-text
    margin-top: 8px
    width: 200px

  .slideMark
    width: 100%
    display: flex
    justify-content: center
    align-items: center

    span
      font-weight: 600

      &:first-child
        margin-right: 5px


.eventModalForm.newForm,
.eventModalForm.editForm
  @extend .wrapperModal
  border-top-left-radius: 5px
  border-top-right-radius: 5px
  position: absolute !important
  // fix important
  padding: 0 !important
// fix important

.eventForm.newForm,
.eventForm.editForm
  @extend .showCard

.newForm, .editForm
  &__cardBlock
    width: 600px

.eventForm.editForm
  display: flex
  justify-content: space-around
  align-items: center

  .ant-descriptions
    background-color: $white
    padding: 10px
    border-radius: 10px

    .ant-descriptions-view
      border-radius: 0

      .ant-descriptions-item-label
        padding: 3px
        color: $black
        text-align: center
        font-weight: 600

      .ant-descriptions-item-label,
      .ant-descriptions-item-content
        font-size: 0.8rem

.eventModalForm.disableEventModalForm
  cursor: not-allowed !important
// fix important


.editForm, .newForm
  position: relative

  &__cardBlock .ant-card-body
    padding: 15px 20px 10px 20px

  &__cardBlock form > div
    width: 400px
    margin: 0 auto 10px auto

    .ant-form-item
      margin-bottom: 10px

    &:last-child
      margin-bottom: 0

      .ant-form-item
        margin-bottom: 0

  &__inputFiled
    width: 300px !important
  // fix important

  .ant-form-item-label
    label
      width: 80px

  &__closeFormButton
    @include iconButtonTest
    font-size: 1.4rem
    font-weight: 600
    position: absolute
    top: 0
    right: 5px


.newForm
  &__cardBlock form > div
    width: 500px

    .ant-form-item-label
      margin-right: 5px

  .cardBlockForm
    &__timestampBlock
      margin-bottom: 15px
      display: flex
      justify-content: space-between

      .ant-form-item
        margin-bottom: 0

        &:first-child
          .ant-form-item-control
            width: 140px

    &__footer
      display: flex
      justify-content: center

      .ant-form-item
        margin-bottom: 0

        &:first-child
          margin-right: 10px




.editForm
  @media (max-height: 750px)
    &__cardBlock
      .ant-card-body
        padding: 5px 15px
        .cardBlockForm
          //width: 400px
          margin: 0 auto

        .ant-descriptions
          padding-top: 0

          .ant-descriptions-view
            .ant-descriptions-row
              .ant-descriptions-item-content
                padding: 0

              &:last-child
                .ant-descriptions-item-content
                  padding: 4px 0

        .cardBlockForm
          &__inputBlock
            margin-bottom: 5px

            .ant-form-item
              margin-bottom: 0











